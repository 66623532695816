import React from "react";
import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";

const Solspace = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Alpha Release Banner */}
      <Link to="/alpha-release">
        <div className="bg-black/30 border border-soless-blue/40 rounded-xl p-6 mb-8 hover:border-soless-blue transition-colors cursor-pointer">
          <h2 className="text-2xl md:text-3xl font-bold text-soless-blue text-center mb-2">
            SOLspace Alpha Release: Own Your Viral Moment
          </h2>
          <p className="text-gray-300 text-center text-lg mb-4">
            Be part of the Genesis Collection - The first 100 viral posts
            preserved on the blockchain
          </p>
          <div className="flex items-center justify-center mt-4 text-soless-blue hover:text-soless-purple">
            <span className="text-sm font-semibold">
              Learn About the Genesis Collection
            </span>
            <ArrowRight className="ml-2 h-4 w-4" />
          </div>
        </div>
      </Link>

      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
        <img
          src="/assets/images/SOLspaceBanner.png"
          alt="SOLspace Banner"
          className="w-full h-auto rounded-xl mb-8"
        />

        {/* Description Section */}
        <div className="bg-black/30 p-4 rounded-xl border border-soless-blue/40 mb-4">
          <h2 className="text-2xl font-bold mb-4 text-soless-blue">
            SOLspace: Own Your Content in the Decentralized Era
          </h2>
          <p className="text-gray-300">
            SOLspace is a revolutionary social network built for the
            decentralized age, designed to give creators true ownership over
            their content. Unlike traditional platforms, SOLspace mints every
            post as an NFT, ensuring creators have verifiable proof of ownership
            and an unalterable record on the blockchain. This platform empowers
            meme creators, influencers, and social enthusiasts with the tools to
            participate in the new digital economy by tracking popularity,
            verifying identity, and directly monetizing their work. Whether
            you're a casual creator or a professional influencer, SOLspace puts
            you in control, offering a unique space to engage, grow, and profit
            from your social impact.
          </p>
        </div>

        {/* Features Section */}
        <div className="bg-black/30 p-4 rounded-xl border border-soless-blue/40 mb-4">
          <h2 className="text-2xl font-bold mb-4 text-soless-blue">Features</h2>

          <div className="space-y-6">
            <div>
              <h3 className="text-xl font-semibold mb-2 text-white">
                NFT Minting for Every Post
              </h3>
              <p className="text-gray-300">
                On SOLspace, every piece of content you share—whether it's text,
                an image, or a meme—is minted as an NFT. This groundbreaking
                feature means you own your content fully and can prove it on the
                blockchain. Unlike traditional social media, where your posts
                can be altered or removed, SOLspace's NFT-based framework
                ensures your work remains truly yours, safeguarded from
                unauthorized changes. This ownership model allows you to build a
                permanent portfolio of your content that can be viewed, shared,
                or even sold in a way that's entirely under your control.
              </p>
            </div>

            <div>
              <h3 className="text-xl font-semibold mb-2 text-white">
                Direct Monetization Options
              </h3>
              <p className="text-gray-300">
                SOLspace provides multiple avenues to monetize your content
                directly. Creators can earn through tipping, where users reward
                valuable posts with tokens, or by offering premium content that
                followers can access with a token-based paywall. Additionally,
                every interaction with your content—likes, shares, and
                comments—contributes to your earning potential through
                SOLspace's tokenized rewards system. By turning engagement into
                financial opportunity, SOLspace lets you generate income from
                your creativity, making it easy to profit from your social reach
                and popularity.
              </p>
            </div>

            <div>
              <h3 className="text-xl font-semibold mb-2 text-white">
                Verified Meme Economy
              </h3>
              <p className="text-gray-300">
                SOLspace thrives within the meme-driven ecosystem of Solana,
                supporting creators with tools to verify their identity, build
                trust, and measure their influence. Verified creators benefit
                from increased visibility, showing followers that their work is
                genuine and valuable. With blockchain-based tracking, SOLspace
                monitors engagement on each post, enabling influencers and
                creators to understand their audience better and expand their
                reach. This verified economy is ideal for those looking to make
                an impact in the rapidly growing world of memes, ensuring that
                every creator's work is recognized, respected, and rewarded.
              </p>
            </div>
          </div>
        </div>

        {/* The SOLspace Advantage */}
        <div className="bg-black/30 p-4 rounded-xl border border-soless-blue/40 mb-4">
          <h2 className="text-2xl font-bold mb-4 text-soless-blue">
            Why Choose SOLspace?
          </h2>

          <div className="space-y-6">
            <div>
              <h3 className="text-xl font-semibold mb-2 text-white">
                Built on Solana for Speed and Scalability
              </h3>
              <p className="text-gray-300">
                Solana is a high-speed blockchain known for its scalability,
                making it ideal for a social platform where thousands of
                interactions happen every second. Solana's minimal fees and
                robust infrastructure are perfect for meme creators and social
                influencers, allowing users to mint NFTs and perform
                transactions affordably. By leveraging Solana, SOLspace provides
                a social network that combines blockchain technology with a
                seamless, user-friendly experience. It's the perfect platform
                for creators to manage their content, build their following, and
                monetize directly in the growing decentralized economy.
              </p>
            </div>

            <div>
              <h3 className="text-xl font-semibold mb-2 text-white">
                The Future of Social Media
              </h3>
              <p className="text-gray-300">
                SOLspace is a next-generation social network, bridging the gap
                between blockchain and social interaction. Unlike traditional
                platforms, which profit from user data and advertisements,
                SOLspace empowers users to benefit from their creativity and
                influence. For meme creators, influencers, and content creators,
                SOLspace represents a fair, user-first platform where your
                content is not only protected but also has tangible value. Join
                the decentralized revolution and experience a social network
                designed for the future of ownership, creativity, and community.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SOLspace = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Alpha Release Banner */}
      <Link to="/alpha-release">
        <div className="bg-black/30 border border-soless-blue/40 rounded-xl p-6 mb-8 hover:border-soless-blue transition-colors cursor-pointer">
          <h2 className="text-2xl md:text-3xl font-bold text-soless-blue text-center mb-2">
            SOLspace Alpha Release: Own Your Viral Moment
          </h2>
          <p className="text-gray-300 text-center text-lg mb-4">
            Be part of the Genesis Collection - The first 100 viral posts
            preserved on the blockchain
          </p>
          <div className="flex items-center justify-center mt-4 text-soless-blue hover:text-soless-purple">
            <span className="text-sm font-semibold">
              Learn About the Genesis Collection
            </span>
            <ArrowRight className="ml-2 h-4 w-4" />
          </div>
        </div>
      </Link>

      {/* Your existing banner */}
      <div className="mb-8">
        <img
          src="/assets/images/DocBanner.png"
          alt="SOLess Banner"
          className="w-full h-auto rounded-xl"
        />
      </div>

      {/* Rest of your SOLspace content */}
    </div>
  );
};

export default Solspace;
