import { Connection, Transaction, TransactionSignature } from "@solana/web3.js";
import { logger } from "./logger";

interface RetryConfig {
  maxRetries?: number;
  initialDelay?: number;
  maxDelay?: number;
}

const DEFAULT_RETRY_CONFIG: RetryConfig = {
  maxRetries: 2,
  initialDelay: 1000,
  maxDelay: 3000,
};

export async function sendAndConfirmTransactionWithRetry(
  connection: Connection,
  transaction: Transaction,
  config: RetryConfig = DEFAULT_RETRY_CONFIG
): Promise<TransactionSignature> {
  let lastError: Error | undefined;
  let delay = config.initialDelay!;

  for (let i = 0; i <= config.maxRetries!; i++) {
    try {
      const signature = await connection.sendRawTransaction(
        transaction.serialize(),
        { skipPreflight: true }
      );

      logger.info(`Transaction sent, signature: ${signature}`);

      // Add timeout to confirmation wait
      const confirmationPromise = connection.confirmTransaction(
        signature,
        "confirmed"
      );
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(
          () => reject(new Error("Transaction confirmation timeout")),
          30000
        );
      });

      const confirmation = await Promise.race([
        confirmationPromise,
        timeoutPromise,
      ]);

      if (
        typeof confirmation === "object" &&
        confirmation !== null &&
        "value" in confirmation &&
        (confirmation as any).value.err
      ) {
        throw new Error(
          `Transaction failed: ${(confirmation as any).value.err.toString()}`
        );
      }

      return signature;
    } catch (error: any) {
      lastError = error;
      logger.warn(`Transaction attempt ${i + 1} failed:`, error);

      if (i < config.maxRetries!) {
        await new Promise((resolve) => setTimeout(resolve, delay));
        delay = Math.min(delay * 2, config.maxDelay!);
      }
    }
  }

  throw lastError || new Error("Transaction failed after all retries");
}
