import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom"; // Make sure to use react-router-dom's Link
const Swap = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Partnership Banner */}
      <Link to="/pioneer-partnership">
        <div className="bg-black/30 border border-red-500/50 rounded-xl p-6 mb-8 hover:border-red-500 transition-colors cursor-pointer">
          <h2 className="text-2xl md:text-3xl font-bold text-red-500 text-center mb-2">
            Join The SOLess Pioneer Partnership Program
          </h2>
          <p className="text-gray-300 text-center text-lg">
            Now accepting applications from Solana's meme coin community
          </p>
          <div className="flex items-center justify-center mt-4 text-red-500 hover:text-red-400">
            <span className="text-sm font-semibold">Learn More</span>
            <ArrowRight className="ml-2 h-4 w-4" />
          </div>
        </div>
      </Link>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-2">
        <img
          src="/assets/images/SwapBanner.png"
          alt="SOLess Swap Banner"
          className="w-full h-auto rounded-xl mb-4"
        />

        {/* Description Section */}
        <div className="bg-black/30 p-6 rounded-xl border border-soless-blue/40 mb-4">
          <h2 className="text-2xl font-bold mb-4 text-soless-blue">
            SOLess Swap: A Flexible, Fee-Free DeFi Exchange on Solana
          </h2>
          <p className="text-gray-300">
            SOLess Swap is a decentralized exchange (DEX) on Solana designed to
            make decentralized finance (DeFi) truly accessible by eliminating
            traditional transaction fees. Through our unique Dynamic Gas Model,
            SOLess Swap allows you to use any token to cover fees, even meme
            coins, unlocking unparalleled flexibility for users. No longer do
            traders need SOL to complete transactions; instead, they can use
            tokens in their wallet, creating an ecosystem that's open, flexible,
            and cost-effective. SOLess Swap brings the power of DeFi to all
            token holders, offering a user-friendly platform that simplifies
            trading across the board.
          </p>
        </div>

        {/* Features Section */}
        <div className="bg-black/30 p-6 rounded-xl border border-soless-blue/40 mb-4">
          <h2 className="text-2xl font-bold mb-4 text-soless-blue">Features</h2>

          <div className="space-y-6">
            <div>
              <h3 className="text-xl font-semibold mb-2 text-white">
                Gasless Trading
              </h3>
              <p className="text-gray-300">
                SOLess Swap's Dynamic Gas Model is a game-changer, allowing
                users to pay transaction fees with any token in their wallet,
                not just SOL. This innovation removes barriers that often hinder
                DeFi adoption, especially for those holding less liquid or niche
                tokens. By making fees payable with any asset, SOLess Swap
                removes the need to keep SOL on hand, creating a more seamless,
                hassle-free trading experience. This unique model is
                particularly beneficial for meme coin enthusiasts who want to
                trade without converting assets for fees, making DeFi accessible
                to everyone, regardless of their token holdings.
              </p>
            </div>

            <div>
              <h3 className="text-xl font-semibold mb-2 text-white">
                Deflationary Tokenomics
              </h3>
              <p className="text-gray-300">
                SOLess Swap employs a deflationary economic model to maintain
                the long-term value of its ecosystem. Every transaction on the
                platform contributes to burn vaults and utilizes transaction
                burn multipliers, which systematically reduce the total supply
                of certain tokens over time. This deflationary approach benefits
                token holders by supporting price stability and enhancing value
                as the token supply decreases. SOLess Swap's deflationary
                mechanics aren't just a feature; they are a foundational part of
                the ecosystem, creating an exchange where participation actively
                contributes to token value appreciation.
              </p>
            </div>

            <div>
              <h3 className="text-xl font-semibold mb-2 text-white">
                Smart Routing and Predictive Analytics
              </h3>
              <p className="text-gray-300">
                SOLess Swap's advanced routing algorithms ensure that every
                trade is executed at the best possible rate, taking into account
                liquidity sources and minimizing slippage. Smart routing
                technology scans multiple paths to find the most cost-efficient
                routes, guaranteeing you get the most out of each trade.
                Additionally, our built-in predictive analytics provide insights
                into market trends, helping users identify optimal trading pairs
                and make informed trading decisions. By combining real-time data
                with powerful analytics, SOLess Swap offers users a competitive
                edge in an ever-evolving market.
              </p>
            </div>
          </div>
        </div>

        {/* The SOLess Swap Advantage */}
        <div className="bg-black/30 p-6 rounded-xl border border-soless-blue/40 mb-4">
          <h2 className="text-2xl font-bold mb-4 text-soless-blue">
            Why Choose SOLess Swap?
          </h2>
          <h3 className="text-xl font-semibold mb-2 text-white">
            Empowering the DeFi Community with Accessible, Low-Cost Trading
          </h3>
          <p className="text-gray-300">
            SOLess Swap makes decentralized finance accessible to all by
            eliminating barriers, especially for tokens that lack easy liquidity
            or traditional support. By allowing any token to function as gas,
            SOLess Swap provides an inclusive platform that gives meme tokens
            and less mainstream assets real utility, helping them gain traction
            in the DeFi ecosystem. SOLess Swap is more than just a DEX; it's a
            community-focused platform built with long-term value in mind. With
            features like gasless trading, deflationary tokenomics, and
            intelligent trading tools, SOLess Swap brings a new level of
            efficiency and inclusivity to DeFi, empowering every token holder to
            trade, hold, and grow their assets.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Swap;
