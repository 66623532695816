import { RouteObject } from "react-router-dom";
import Home from "../pages/Home";
import Presale from "../pages/Presale";
import SOLspace from "../pages/Solspace";
import Solarium from "../pages/Solarium";
import Swap from "../pages/Swap";
import Admin from "../pages/Admin";
import Documentation from "../pages/Documentation";
import PioneerPartnership from "../pages/pioneer-partnership"; // Add this import
import AlphaRelease from "../pages/AlphaRelease";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/presale",
    element: <Presale />,
  },
  {
    path: "/solspace",
    element: <SOLspace />,
  },
  {
    path: "/solarium",
    element: <Solarium />,
  },
  {
    path: "/swap",
    element: <Swap />,
  },
  {
    path: "/docs",
    element: <Documentation />,
  },
  {
    path: "/admin",
    element: <Admin />,
  },
  // Add the new route
  {
    path: "/pioneer-partnership",
    element: <PioneerPartnership />,
  },
  {
    path: "/alpha-release",
    element: <AlphaRelease />,
  },
];

export default routes;
