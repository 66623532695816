import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6">
      {/* Banner and logo stacked vertically */}
      <div className="flex flex-col items-center mb-0">
        <img
          src="/assets/images/WordBanner.png"
          alt="SOLess Banner"
          className="w-full max-w-4xl mx-auto h-auto"
        />
        <img
          src="/assets/images/HomePageLogo.png"
          alt="SOLess Logo"
          className="h-72 w-auto mt-0"
        />
      </div>

      <div className="text-center mb-8">
        {" "}
        {/* Reduced spacing */}
        <p className="text-2xl text-gray-300 mb-4">
          {" "}
          {/* Reduced margin */}
          Unlock the power of gasless DeFi and true content ownership—swap,
          earn, and thrive in the SOLess ecosystem on Solana.
        </p>
        <Link
          to="/presale"
          className="inline-block bg-gradient-to-r from-soless-blue to-soless-purple px-8 py-3 rounded-lg font-semibold hover:opacity-90 transition-opacity"
        >
          Join Presale
        </Link>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {" "}
        {/* Reduced gap */}
        {/* Info boxes with reduced spacing */}
        <div className="bg-black/30 p-6 rounded-xl border border-z">
          {" "}
          {/* Reduced padding */}
          <h3 className="text-2xl font-bold mb-4 text-soless-blue">
            SOLess Swap
          </h3>
          <p className="text-gray-300">
            SOLess Swap allows users to pay gas fees in any listed token – even
            meme coins – eliminating the need for SOL to cover transaction
            costs. By doing this we can tap into Solana's $11B Meme Coin Market.
          </p>
        </div>
        <div className="bg-black/30 p-8 rounded-xl border border-soless-blue/40">
          <h3 className="text-2xl font-bold mb-4 text-soless-blue">SOLspace</h3>
          <p className="text-gray-300">
            SOLspace is more than just a social network; it's a decentralized
            ecosystem where ownership, authenticity, and community come
            together. Say goodbye to data exploitation and platform control.
          </p>
        </div>
        <div className="bg-black/30 p-8 rounded-xl border border-soless-blue/40">
          <h3 className="text-2xl font-bold mb-4 text-soless-blue">SOLarium</h3>
          <p className="text-gray-300">
            SOLarium is a dedicated vault and marketplace for NFT art and social
            content created on SOLspace. When users mint NFTs, a portion of the
            minting price is used to buy SOLess tokens.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
