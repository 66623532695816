// src/pages/alpha-release.tsx
import React from "react";
import { ChevronRight } from "lucide-react";

const AlphaRelease = () => {
  const tiers = [
    {
      level: "Tier 1",
      requirements: "1,000+ likes or 200+ retweets",
      status: "Initial Mint",
    },
    {
      level: "Tier 2",
      requirements: "2,500+ likes or 500+ retweets",
      status: "Automatic Upgrade",
    },
    {
      level: "Tier 3",
      requirements: "5,000+ likes or 1,000+ retweets",
      status: "Maximum Tier",
    },
  ];

  const genesisBenefits = [
    "Exclusive 'Genesis' badge",
    "Numbered 1-100 for authenticity",
    "Enhanced governance rights",
    "Permanent showcase in SOLspace history",
    "Early access to platform features",
    "Beta testing opportunities",
    "Special showcase positions",
  ];

  const rarityFactors = [
    "Mint order (Genesis, Early Bird, or Alpha status)",
    "Engagement metrics (likes, retweets, replies)",
    "Time to viral status",
    "Overall impact and reach",
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Header Section */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold bg-gradient-to-r from-soless-blue to-soless-purple bg-clip-text text-transparent mb-4">
          SOLspace Alpha Release: Own Your Viral Moment
        </h1>
        <p className="text-xl text-gray-300">
          Revolutionary Content Preservation on the Blockchain
        </p>
      </div>
      {/* Main Description */}
      <div className="bg-black/30 p-8 rounded-xl border border-soless-blue/40 mb-8">
        <p className="text-gray-300 text-lg leading-relaxed">
          SOLspace Alpha is introducing a groundbreaking way to preserve and own
          viral social media moments. Using advanced viral detection technology,
          we automatically identify and mint trending content as unique NFTs,
          giving creators true ownership of their viral success.
        </p>
      </div>
      <div className="bg-black/30 p-8 rounded-xl border border-soless-blue/40 mb-8">
        <h2 className="text-2xl font-bold text-soless-blue mb-6">
          How You'll Be Notified
        </h2>
        <div className="mb-6">
          <img
            src="/assets/images/notification-template.png"
            alt="SOLspace Viral Post Notification"
            className="rounded-lg w-full max-w-2xl mx-auto"
          />
        </div>
      </div>
      {/* Genesis Collection */}
      <div className="bg-black/30 p-8 rounded-xl border border-soless-blue/40 mb-8">
        <h2 className="text-2xl font-bold text-soless-blue mb-6">
          The Genesis Collection
        </h2>
        <p className="text-gray-300 mb-6">
          The first 100 viral posts minted on SOLspace will become the exclusive
          "Genesis Collection" — a historic set of NFTs representing the
          foundation of decentralized social media.
        </p>
        <div className="grid md:grid-cols-2 gap-6">
          <div>
            <h3 className="text-xl font-semibold text-gray-200 mb-4">
              Genesis Benefits
            </h3>
            <ul className="space-y-2">
              {genesisBenefits.map((benefit, index) => (
                <li key={index} className="flex items-start text-gray-300">
                  <ChevronRight className="h-5 w-5 text-soless-blue shrink-0 mt-0.5" />
                  <span>{benefit}</span>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-semibold text-gray-200 mb-4">
              Rarity Factors
            </h3>
            <ul className="space-y-2">
              {rarityFactors.map((factor, index) => (
                <li key={index} className="flex items-start text-gray-300">
                  <ChevronRight className="h-5 w-5 text-soless-blue shrink-0 mt-0.5" />
                  <span>{factor}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {/* How It Works */}
      <div className="bg-black/30 p-8 rounded-xl border border-soless-blue/40 mb-8">
        <h2 className="text-2xl font-bold text-soless-blue mb-6">
          How It Works
        </h2>
        <div className="grid md:grid-cols-3 gap-6">
          {tiers.map((tier, index) => (
            <div key={index} className="bg-black/20 p-6 rounded-lg">
              <h3 className="text-xl font-semibold text-soless-blue mb-2">
                {tier.level}
              </h3>
              <p className="text-gray-300 mb-2">{tier.requirements}</p>
              <p className="text-sm text-gray-400">{tier.status}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-black/30 p-8 rounded-xl border border-soless-blue/40 mb-8">
        <h2 className="text-2xl font-bold text-soless-blue mb-6">
          Viral Post Monitoring
        </h2>
        <div className="mb-6">
          <img
            src="/assets/images/viral-monitor.png"
            alt="SOLspace ALPHA Viral Post Monitor Dashboard"
            className="rounded-lg w-full"
          />
        </div>
      </div>
      {/* $SOUL Integration */}
      <div className="bg-black/30 p-8 rounded-xl border border-soless-blue/40 mb-8">
        <h2 className="text-2xl font-bold text-soless-blue mb-6">
          Future SOLess Integration
        </h2>
        <div className="space-y-6">
          <p className="text-gray-300">
            When $SOUL launches, Genesis NFTs will be eligible for floor price
            valuations based on their rarity scores and historical significance.
            Each NFT's floor price is protected and backed by SOLess tokens,
            creating a permanent value foundation.
          </p>
          <div className="bg-black/20 p-6 rounded-lg">
            <h3 className="text-xl font-semibold text-soless-blue mb-4">
              Example Rarity Score Calculation
            </h3>
            <ul className="space-y-2 text-gray-300">
              <li>Genesis Status (1-10): 1,000 points</li>
              <li>Viral Engagement: 300 points</li>
              <li>12-Hour Velocity Bonus: 100 points</li>
              <li>Week 1 Bonus: 200 points</li>
              <li>Early Number Bonus: 1,000 points</li>
              <li className="font-semibold mt-4">Total: 2,600 points</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="bg-black/30 p-8 rounded-xl border border-soless-blue/40 mb-8">
        <h2 className="text-2xl font-bold text-soless-blue mb-6">
          Understanding Floor Price Mechanism
        </h2>
        <div className="space-y-6">
          <h3 className="text-xl font-semibold text-gray-200">
            Floor Price Tiers
          </h3>
          <div className="grid md:grid-cols-3 gap-4">
            <div className="bg-black/20 p-4 rounded-lg">
              <h4 className="text-soless-blue font-semibold mb-2">Tier 3</h4>
              <p className="text-gray-300">
                2000+ points: Premium floor protection
              </p>
            </div>
            <div className="bg-black/20 p-4 rounded-lg">
              <h4 className="text-soless-blue font-semibold mb-2">Tier 2</h4>
              <p className="text-gray-300">
                1000–1999 points: Enhanced floor protection
              </p>
            </div>
            <div className="bg-black/20 p-4 rounded-lg">
              <h4 className="text-soless-blue font-semibold mb-2">Tier 1</h4>
              <p className="text-gray-300">
                &lt;1000 points: Standard floor protection
              </p>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <h3 className="text-xl font-semibold text-gray-200 mb-4">
            Market Cap Scenarios
          </h3>
          <div className="grid md:grid-cols-3 gap-6">
            <div className="bg-black/20 p-6 rounded-lg">
              <h4 className="text-soless-blue font-semibold mb-2">
                $2M Market Cap
              </h4>
              <ul className="space-y-2 text-gray-300">
                <li>Total Floor Pool: $100,000</li>
                <li>SOLess Price: $0.0002</li>
                <li>Genesis Tier 3 Floor Value: $300</li>
              </ul>
            </div>
            <div className="bg-black/20 p-6 rounded-lg">
              <h4 className="text-soless-blue font-semibold mb-2">
                $50M Market Cap
              </h4>
              <ul className="space-y-2 text-gray-300">
                <li>Total Floor Pool: $2.5M</li>
                <li>SOLess Price: $0.005</li>
                <li>Genesis Tier 3 Floor Value: $7,500</li>
              </ul>
            </div>
            <div className="bg-black/20 p-6 rounded-lg">
              <h4 className="text-soless-blue font-semibold mb-2">
                $600M Market Cap
              </h4>
              <ul className="space-y-2 text-gray-300">
                <li>Total Floor Pool: $30M</li>
                <li>SOLess Price: $0.06</li>
                <li>Genesis Tier 3 Floor Value: $90,000</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black/30 p-8 rounded-xl border border-soless-blue/40 mb-8">
        <h2 className="text-2xl font-bold text-soless-blue mb-6">
          Dynamic Value Components
        </h2>
        <p className="text-gray-300">
          SOLspace's floor price system creates a resilient value structure by
          ensuring each NFT maintains guaranteed worth regardless of market
          conditions. By backing NFTs with SOLess tokens, early adopters are
          rewarded with permanent platform benefits and increasing value
          potential as the ecosystem grows.
        </p>
        <ul className="mt-4 space-y-2">
          <li className="flex items-start text-gray-300">
            <ChevronRight className="h-5 w-5 text-soless-blue shrink-0 mt-0.5" />
            <span>Guaranteed minimum value in $SOUL tokens</span>
          </li>
          <li className="flex items-start text-gray-300">
            <ChevronRight className="h-5 w-5 text-soless-blue shrink-0 mt-0.5" />
            <span>Priority access to SOLarium vault services</span>
          </li>
          <li className="flex items-start text-gray-300">
            <ChevronRight className="h-5 w-5 text-soless-blue shrink-0 mt-0.5" />
            <span>Enhanced liquidity options</span>
          </li>
          <li className="flex items-start text-gray-300">
            <ChevronRight className="h-5 w-5 text-soless-blue shrink-0 mt-0.5" />
            <span>
              Ability to use the NFT as collateral anywhere in the SOLess System
            </span>
          </li>
        </ul>
      </div>
      {/* Call to Action */}
      <div className="text-center">
        <p className="text-gray-300 mb-4">
          Be part of social media history by joining the Genesis Collection.
        </p>
        <a
          href="https://twitter.com/SOLessSystem"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block bg-gradient-to-r from-soless-blue to-soless-purple px-8 py-3 rounded-lg text-lg font-semibold hover:opacity-90 transition-opacity"
        >
          Follow @SOLessSystem for Updates
        </a>
      </div>
    </div>
  );
};

export default AlphaRelease;
