import React from "react";
import { BookOpen, Newspaper, FileText } from "lucide-react";

const Documentation = () => {
  const whitepapers = [
    {
      title: "SOLess: A Gasless Decentralized Exchange on Solana",
      description:
        "A comprehensive overview of SOLess's gasless DEX, featuring unique tokenomics and Soulie mascot.",
      link: "/whitepapers/SOLess%20Swap%20v3.pdf",
      version: "V1.2",
      date: "October 2024",
    },
    {
      title: "SOLspace: A Blockchain-Based Social Media Platform",
      description:
        "Overview of decentralized social platform with content ownership and NFT integration.",
      link: "/whitepapers/SOLspace%20Whitepaper%20V1.0.pdf",
      version: "V1.0",
      date: "November 2024",
    },
    {
      title: "SOLarium: A Vault for Valuable NFT Art",
      description:
        "Detailed analysis of SOLarium's NFT vault solution with value preservation features.",
      link: "/whitepapers/SOLarium%20Whitepaper%20V1.0.pdf",
      version: "V1.0",
      date: "November 2024",
    },
  ];

  const articles = [
    {
      title: "SOLarium: Transforming NFT Ownership",
      description:
        "Deep dive into SOLarium's innovative approach to NFT liquidity and value preservation.",
      link: "/articles/SOLarium%20Article%201%20(LIQUIDITY).pdf",
      category: "NFT Innovation",
      date: "November 2024",
    },
    {
      title: "SOLspace: Revolutionizing Social Media",
      description:
        "How SOLspace is redefining content creation and ownership in social media.",
      link: "/articles/SOLspace%20Article%201%20(CREATORS).pdf",
      category: "Social Media",
      date: "November 2024",
    },
    {
      title: "SOLess.app: The Game-Changing Gasless DEX",
      description:
        "Exploring the impact of gasless transactions on meme token utility.",
      link: "/articles/SOLessSwap%20Article%201%20(MEME).pdf",
      category: "DeFi Innovation",
      date: "November 2024",
    },
    {
      title:
        "How SOLspace will Transform Social Discourse Through Source Verification and Transparent Engagement",
      description:
        "By leveraging blockchain technology to integrate source verification and engagement tracking, SOLspace is not just imagining a better social media experience — it’s actively building one.",
      url: "https://medium.com/@team_94982/how-solspace-will-transform-social-discourse-through-source-verification-and-transparent-engagement-ddcc445ae3ee",
      date: "November 2024",
      readTime: "5 min read",
    },
    {
      title: "SOLSpace 1.0 - Guerilla Minting",
      description:
        "By leveraging blockchain technology to integrate source verification and engagement tracking, SOLspace is not just imagining a better social media experience — it’s actively building one.",
      url: "https://medium.com/@team_94982/solspace-1-0-guerilla-minting-8c7a98684c18",
      date: "December 2024",
      readTime: "2 min read",
    },
    {
      title: "The Math Behind the Burn: Why SOLess Is the Launch of the Year",
      description:
        "Backed by brilliant tokenomics, relentless burn mechanisms, and a multi-platform ecosystem, SOLess is the token that doesn’t just have utility—it rewrites the rules of blockchain innovation",
      url: "https://medium.com/@team_94982/the-math-behind-the-burn-why-soless-is-the-launch-of-the-year-76f002591f90",
      date: "December 2024",
      readTime: "3 min read",
    },
    {
      title:
        "The SOLess Ecosystem: A Mathematical Approach to Digital Value Creation",
      description:
        "The SOLess ecosystem introduces a groundbreaking three-part system where each component not only serves its primary function but mathematically reinforces the others, creating a self-sustaining cycle of value creation",
      url: "https://medium.com/@team_94982/the-soless-ecosystem-a-mathematical-approach-to-digital-value-creation-d2082867e06c",
      date: "December 2024",
      readTime: "2 min read",
    },
    {
      title:
        "The Future of Social Media and Deflationary Crypto: How the SOLess System and SOUL Token Are Redefining the Game",
      description:
        "Three interconnected platforms powered by the SOUL token. Together, they are set to revolutionize DeFi, social media, and NFTs while introducing deflationary mechanics that could reshape the market forever.",
      url: "https://medium.com/@team_94982/the-future-of-social-media-and-deflationary-crypto-how-solspace-and-soul-token-are-redefining-the-5b1e55c8798d",
      date: "December 2024",
      readTime: "5 min read",
    },
    {
      title: "The SOLess System: Market Potential Analysis",
      description:
        "This analysis explores the potential market valuation of the $SOUL token that powers this ecosystem.",
      url: "https://medium.com/@team_94982/the-soless-system-market-potential-analysis-bd6e5359204b",
      date: "December 2024",
      readTime: "2 min read",
    },
    {
      title: "SOUL Token: Powering the Future of Social Media, NFTs, and DeFi",
      description:
        "The crypto space is about to witness the launch of one of its most ambitious projects yet",
      url: "https://medium.com/@team_94982/soul-token-powering-the-future-of-social-media-nfts-and-defi-085cd68174a9",
      date: "December 2024",
      readTime: "5 min read",
    },
    {
      title: "SOLspace Alpha Release: Own Your Viral Moment",
      description:
        "SOLspace Alpha is introducing a groundbreaking way to preserve and own viral social media moments.",
      url: "https://medium.com/@team_94982/solspace-alpha-release-own-your-viral-moment-0df8c51142c5",
      date: "December 2024",
      readTime: "4 min read",
    },
  ];

  const press = [
    {
      title: "SOLess Ecosystem Announces Token Presale",
      description:
        "A comprehensive look at the SOLess ecosystem launch and presale announcement.",
      link: "/press/SOLess%20Press%20Release.pdf",
      date: "November 17, 2024",
    },
  ];

  const DocSection = ({
    title,
    items,
    icon: Icon,
  }: {
    title: string;
    items: any[];
    icon: React.ComponentType<any>;
  }) => (
    <section className="mb-12">
      <div className="flex items-center gap-2 mb-6">
        <Icon className="h-6 w-6 text-soless-blue" />
        <h2 className="text-2xl font-bold bg-gradient-to-r from-soless-blue to-soless-purple bg-clip-text text-transparent">
          {title}
        </h2>
      </div>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {items.map((item) => (
          <div
            key={item.title}
            className="bg-black/30 p-6 rounded-xl border border-soless-blue/40 hover:border-soless-blue transition-colors flex flex-col h-full"
          >
            <div className="flex-grow">
              <div className="flex justify-between items-start mb-2">
                {item.category && (
                  <div className="text-xs text-soless-blue">
                    {item.category}
                  </div>
                )}
                {item.version && (
                  <div className="text-xs text-soless-blue">{item.version}</div>
                )}
              </div>
              <h3 className="text-lg font-semibold text-gray-200 mb-2">
                {item.title}
              </h3>
              <p className="text-gray-300 text-sm mb-4">{item.description}</p>
              <div className="text-xs text-gray-400">{item.date}</div>
            </div>
            <a
              href={item.link}
              target={item.isExternal ? "_blank" : "_self"}
              rel={item.isExternal ? "noopener noreferrer" : ""}
              className="inline-block bg-gradient-to-r from-soless-blue to-soless-purple px-4 py-2 rounded-lg text-sm font-semibold hover:opacity-90 transition-opacity w-full text-center mt-4"
            >
              {item.isExternal ? "Read on Medium" : "Read"}
            </a>
          </div>
        ))}
      </div>
    </section>
  );

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Banner */}
      <div className="mb-8">
        <img
          src="/assets/images/DocBanner.png"
          alt="SOLess Banner"
          className="w-full h-auto rounded-xl"
        />
      </div>

      {/* Main Content */}
      <div>
        <DocSection title="Whitepapers" items={whitepapers} icon={BookOpen} />
        <DocSection title="Articles" items={articles} icon={FileText} />
        <DocSection title="Press Releases" items={press} icon={Newspaper} />
      </div>

      {/* Soulie at bottom */}
      <div className="flex justify-center mt-12">
        <img
          src="/assets/images/PresaleSoulie.png"
          alt="Soulie"
          className="h-48 w-auto"
        />
      </div>
    </div>
  );
};

export default Documentation;
