const Solarium = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-2">
      <img
        src="/assets/images/SolariumBanner.png"
        alt="SOLarium Banner"
        className="w-full h-auto rounded-xl mb-4"
      />

      {/* Description Section */}
      <div className="bg-black/30 p-6 rounded-xl border border-soless-blue/40 mb-4">
        <h2 className="text-2xl font-bold mb-4 text-soless-blue">
          SOLarium: A Vault for Valuable NFT Art and Social Content
        </h2>
        <p className="text-gray-300">
          SOLarium is a pioneering vault designed to safeguard and enhance the
          value of NFTs minted on SOLcial. More than just a storage solution,
          SOLarium adds real, enduring value to every NFT by tying each to a
          built-in floor price, backed by SOLess tokens. This structure ensures
          that your NFTs retain a base value, offering peace of mind to creators
          and collectors alike. SOLarium also provides flexible liquidity
          options, allowing users to trade their NFTs back to the vault at a
          guaranteed floor price. This unique system empowers creators with
          financial stability and flexibility, making SOLarium the ultimate
          space for preserving and monetizing digital art and content.
        </p>
      </div>

      {/* Features Section */}
      <div className="bg-black/30 p-6 rounded-xl border border-soless-blue/40 mb-4">
        <h2 className="text-2xl font-bold mb-4 text-soless-blue">Features</h2>

        <div className="space-y-6">
          <div>
            <h3 className="text-xl font-semibold mb-2 text-white">
              Guaranteed NFT Value
            </h3>
            <p className="text-gray-300">
              Every NFT minted on SOLcial gains intrinsic value thanks to its
              integration with SOLess tokens, a native asset backing each piece.
              A portion of the minting fee for each NFT is allocated to SOLess
              tokens, which establishes a minimum or "floor" price for the NFT.
              This floor value means that, unlike typical NFTs, SOLarium-backed
              NFTs will always retain a base value, ensuring creators and
              collectors alike can have confidence in the lasting worth of their
              assets. This feature uniquely positions SOLarium as an ideal vault
              for maintaining long-term value, even during market fluctuations.
            </p>
          </div>

          <div>
            <h3 className="text-xl font-semibold mb-2 text-white">
              Built-In Liquidity
            </h3>
            <p className="text-gray-300">
              SOLarium addresses one of the biggest challenges in the NFT
              market: liquidity. With traditional NFTs, owners often wait for
              interested buyers to provide liquidity, which can delay access to
              funds and add market risk. SOLarium, however, allows users to
              trade their NFTs back to the vault at the pre-set floor price
              without waiting for external buyers. This instant liquidity
              option, backed by SOLess tokens, offers creators and collectors
              flexibility to access funds as needed. Whether you need immediate
              liquidity or want to hold your NFT as a long-term investment,
              SOLarium provides financial options for any situation.
            </p>
          </div>

          <div>
            <h3 className="text-xl font-semibold mb-2 text-white">
              Personal Gallery and Vault
            </h3>
            <p className="text-gray-300">
              SOLarium is more than a vault; it's also a personal gallery space
              where users can curate, display, and manage their NFT collections.
              This secure, censorship-resistant gallery allows you to showcase
              your most valued assets within an environment that prioritizes
              privacy and autonomy. Whether you're displaying digital art,
              exclusive content, or collectible memes, your SOLarium gallery is
              tailored to let you present and protect your assets as you see
              fit. Each item in your vault remains protected, ensuring that your
              digital presence is preserved and that your work is always visible
              to your audience.
            </p>
          </div>
        </div>
      </div>

      {/* The SOLarium Advantage */}
      <div className="bg-black/30 p-6 rounded-xl border border-soless-blue/40 mb-4">
        <h2 className="text-2xl font-bold mb-4 text-soless-blue">
          Why Choose SOLarium?
        </h2>
        <h3 className="text-xl font-semibold mb-2 text-white">
          Sustainable Value and Liquidity for NFTs
        </h3>
        <p className="text-gray-300">
          SOLarium offers a comprehensive solution for NFT creators and
          collectors who want sustainable value and guaranteed liquidity. Unlike
          traditional NFTs that can lose value in volatile markets,
          SOLarium-backed NFTs are fortified with a minimum floor price thanks
          to the integration of SOLess tokens. This ensures that creators' hard
          work and creativity are preserved with real value. By choosing
          SOLarium, you gain access to a platform where digital art, memes, and
          social content are not only safe but also retain accessibility and
          value. SOLarium redefines what it means to own and invest in NFTs by
          providing a sustainable ecosystem that protects and empowers creators
          for the long term.
        </p>
      </div>
    </div>
  );
};

export default Solarium;
