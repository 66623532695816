import { Link } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { Twitter, MessageCircle, BookOpen, Menu, X } from "lucide-react";
import { useState } from "react";

const Navbar = () => {
  const { publicKey } = useWallet();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Check if connected wallet is admin
  const isAdmin =
    publicKey?.toBase58() === "8rYNzisESAJZAJGZiyosNkVb1tbrWhsgQkLgavj6Ytyj";

  const navigationItems = [
    { path: "/swap", label: "SOLessSwap" },
    { path: "/solspace", label: "SOLspace" },
    { path: "/solarium", label: "SOLarium" },
    { path: "/presale", label: "PRESALE", className: "text-cyan-400" },
    { path: "/docs", label: "Docs", icon: BookOpen },
    ...(isAdmin
      ? [{ path: "/admin", label: "ADMIN", className: "text-purple-400" }]
      : []),
  ];

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-black/50 backdrop-blur-lg border-b border-soless-blue/40">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo and Mobile Menu Button */}
          <div className="flex items-center">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white md:hidden"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
            <Link to="/" className="text-xl text-soless-blue ml-2 md:ml-0">
              SOLess
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex md:items-center md:space-x-6">
            {navigationItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={`text-gray-300 hover:text-white flex items-center gap-1 ${
                  item.className || ""
                }`}
              >
                {item.icon && <item.icon className="h-4 w-4" />}
                {item.label}
              </Link>
            ))}
          </div>

          {/* Social Links and Wallet Button */}
          <div className="flex items-center space-x-4">
            <div className="hidden md:flex items-center space-x-4">
              <a
                href="https://x.com/SOLessSystem"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-300 hover:text-white"
              >
                <Twitter className="h-5 w-5" />
              </a>
              <a
                href="https://t.me/SolessSystem"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-300 hover:text-white"
              >
                <MessageCircle className="h-5 w-5" />
              </a>
            </div>
            <WalletMultiButton />
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 bg-black/90">
            {navigationItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={`block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-black/50 ${
                  item.className || ""
                }`}
                onClick={() => setIsMenuOpen(false)}
              >
                <div className="flex items-center gap-2">
                  {item.icon && <item.icon className="h-4 w-4" />}
                  {item.label}
                </div>
              </Link>
            ))}
            <div className="flex items-center space-x-4 px-3 py-2">
              <a
                href="https://x.com/SOLessSystem"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-300 hover:text-white"
              >
                <Twitter className="h-5 w-5" />
              </a>
              <a
                href="https://t.me/Soless_System"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-300 hover:text-white"
              >
                <MessageCircle className="h-5 w-5" />
              </a>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
