import { FC, ReactNode, useMemo } from "react";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  AlphaWalletAdapter,
  CoinbaseWalletAdapter,
  LedgerWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";

import "@solana/wallet-adapter-react-ui/styles.css";

interface Props {
  children: ReactNode;
}

const PRIVATE_RPC =
  "https://solana-mainnet.core.chainstack.com/d758b1882ae4bd577530e643d63f8aa5";

export const WalletContextProvider: FC<Props> = ({ children }) => {
  const endpoint = useMemo(() => PRIVATE_RPC, []);

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter({
        appName: "SOLess Presale",
        network: "mainnet-beta",
      }),
      new SolflareWalletAdapter(),
      new AlphaWalletAdapter(),
      new CoinbaseWalletAdapter({
        detached: true,
        overrideNavigate: false,
      }),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
    ],
    []
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default WalletContextProvider;
